#lng .lang a {
    padding: 23px 15px;
    line-height: 14px;
}

#lngChooser {
    right: 100px;
    display: table-row;
    .lang {
        display: table-cell;
        vertical-align: middle;
    }
    .active a { color: $activeMenu_txtColor; font-weight: bold; }
}

#languageChooser { display: none; }

.spinner-master * {
    transition: all 0.3s;
    box-sizing: border-box;
}

.spinner-master {
    position: relative;
    margin: 15px;
    height: 30px;
    width: 30px;
    float: left;
}

.spinner-master label {
    cursor: pointer;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 5px;
    left: 0;
}

.spinner-master .spinner {
    position: absolute;
    height: 4px;
    width: 100%;
    padding: 0;
    background-color: #333;
}

.spinner-master .diagonal.part-1 {
    position: relative;
    float: left;
}

.spinner-master .horizontal {
    position: relative;
    float: left;
    margin-top: 4px;
}

.spinner-master .diagonal.part-2 {
    position: relative;
    float: left;
    margin-top: 4px;
}

.spinner-master input[type=checkbox]:checked ~ .spinner-spin > .horizontal { opacity: 0; }

.spinner-master input[type=checkbox]:checked ~ .spinner-spin > .diagonal.part-1 {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-top: 10px;
}

.spinner-master input[type=checkbox]:checked ~ .spinner-spin > .diagonal.part-2 {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: -12px;
}

a.menu-link {
    display: block;
    color: #fff;
    float: left;
    text-decoration: none;
    padding: 10px 16px;
    font-size: 1.5em;
}

a.menu-link:hover { color: #d1050c; }

a.menu-link:after {
    content: "\2630";
    font-weight: normal;
}

a.menu-link.active:after { content: "\2715"; }

.menu {
    clear: both;
    // min-width: inherit;
    float: none;
}

.menu,
.menu > ul ul {
    overflow: hidden;
    max-height: 0;
    background-color: rgba(254, 254, 254, 0.2);
}

.menu > li > ul.sub-menu {
    padding: 0px;
    border: none;
}

.menu.active,
.menu > ul ul.active { max-height: 55em; }

.menu ul { display: inline; }

.menu li,
.menu > ul > li { display: block; }

.menu > ul > li:last-of-type a { border: none; }

.menu li a {
    color: #fff;
    display: block;
    padding: 0.8em;
    position: relative;
}

.menu li.has-submenu > a:after {
    content: '+';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    font-size: 1.5em;
    padding: 0.55em 0.5em;
}

.menu li.has-submenu > a.active:after { content: "-"; }

.menu ul ul > li a {
    background-color:  rgba(254, 254, 254, 0.2);
    padding: 10px 18px 10px 30px;
}

.menu ul li a:hover {
    background: #4b5f65;
    color: #fff;
}

.menu ul li.hover > a {
    background: #4b5f65;
    color: #fff;
}

.menu ul ul,
.menu ul ul ul {
    display: inherit;
    position: relative;
    left: auto;
    top: auto;
    border: none;
}

// responsive pagination
.pagination {
    .page,
    .ellipsis { display: none; }

    a:first-of-type,
    a.current,
    a:last-of-type {
        font-size: 1.3em;
        display: block;
    }
}