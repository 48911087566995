/* Global SCSS variables, used in order to simplify the life in general */

$font-size: 16px;

/* Backgroud Colors */
$header_bgColor: #424242;
$body_bgColor: #E8D9C2;
$footer_bgColor: #424242;
$section_bgColor: #DDCCB2;
$form_bgColor: #EBDECB;
$fullWidthColumn_bgColor: #ff0000;
$activeMenu_bgColor: #333;


/* Text Colors */
$activeMenu_txtColor: #d1050c;
$anchor_hover_txtColor: #7d1619;
$defaultMenu_txtColor: #000000;
$fontColor: #565655;


/*  Box sizes */
$wrapper_width: 944px;
$navMenu_height: 53px;
$menuItem_width: 160px;
$header_height: 352px;
$section_height: 357px;
$footer_height: 69px;