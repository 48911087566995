// Site color settings & box sizes
@import 'constants';
@import 'navigation_responsive';

// OBS:
//  - For iPhone and iPad devices, the device-width always corresponds to the width of the device in portrait
//    mode (ie: 768px), regardless of whether the device is in that mode or landscape instead.
//  - With other devices, its device-width changes depending on its orientation.

//----------------------------------
//-- Page Layout related settings --
//----------------------------------

    #pageWrapper {
        padding: 60px 0 0 0;
        box-sizing: border-box; }

    #headerContent {
        height: auto;
        width: 100%;
    }

    #pageHeader {
        height: auto;
    }

    #menuSwitcherBtnContainer,
    // .carousel,
    .footerContent > .rightSideColumn {
        display: block;
    }

    // Replace the carousel with static image on narrow screen
    .carousel { display: none; }
    .carouselNarrowScreen {
        display: block;
        img {
            // position: absolute;
            display: block;
            /* make image flexible */
            max-width: 100%;
            height: auto;
            width: auto\9; /* IE8*/
        }
    }

    .nav_wrapper {
        height: auto;
        margin: 0; padding:0;
    }

    #headerContent > .carouselMobile {
        width: 100%;
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    #bodyContent {
        background: none;
        box-shadow: none;
        width: auto;
    }

    // Adjust footer
    .footerContent {
        width: 100%;
        .leftSideColumn { width: 100%; }
        .rightSideColumn { width: 100%; }
        .leftFooter {
            padding-left: 4px;
            img { padding-right: 4px; }
        }
    }

    .leftSideColumn,
    .rightSideColumn {
        width: 100%;
        float: none;
    }

    .backgroundTwoCols {
        overflow: auto;
        background: #ddccb2;
    }

    li#avatar, li#lng, #lngChooser, .lang  { height: 60px; }

    .title {
        width: 100%;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
        border-bottom: 1px dotted #C1B29C;
        padding: 10px;
        box-sizing: border-box;
    }

    .triangle-topleft { display: none; }
    .padding { padding: 10px; }
    .btn { padding: 10px 15px; }

//------------------------------
//-- About page               --
//------------------------------
    #siteLogo {
        display: none;
     }

    .rightSideColumn.aboutPage {
        display: block;
    }


//------------------------------
//-- Login page               --
//------------------------------


//------------------------------
//-- Tree reg form            --
//------------------------------
    #treeRecordingForm {
        font-size: 1.1em;

        fieldset {
            margin: 0;
            padding: 0;
            border: none;
            padding-bottom: 30px;
        }

        legend {
            background: #DDCCB2;
            width: 100%;
            padding: 10px 0 10px 5px;
            // margin-bottom: 15px;
            border-radius: 3px;
        }

        label {
            display: inline-block;
            line-height: 1em;
        }

        textarea { height: 4em; }
        input { right: 0; }
        select { right: 0; width: 40%; }


        .mandatoryFieldsTree {

            label {
                width: 180px;
            }

            .species { width: 100px; }
            .foto { width: auto; }

            input {
                float: right;
                width: 100px;
            }

            select {
                float: right;
                width: 180px;
            }

            #id_image {
                float: none;
                width: auto;
                margin-top: 10px;
                font-size: 1.1em;
            }
        }

        // .treeCondition {}

        // .humanImpact {}

        .treeLocation {
            .landscapeOpts .fieldWrapper { margin: 0; }
        }

        .treeImportance {
            textarea { height: 8em; }
            .fieldWrapper { margin: 0; }
         }

        #id_other { margin: 0; width: 99%; }

        .treeFormImg {
            margin: 0;
            padding-left: 44px;
            float: none;
        }

        .mandatoryFieldsTree, .treeCondition, .humanImpact, .treeLocation, .treeImportance {
            width: 100%;
            height: auto;
            margin-top: 0;
        }

        .fieldWrapper { margin: 16px 0; }

        .moreInfoSignBlind { display: none; }

        #id_location, #id_private_public, .grouppingOpts {
            margin: 0 0 0 15px;
            padding: 10px 0;
            li { height: 30px; }
        }
        .noMargin { margin: 0; }

        .container { margin: 0; }
        .fieldsetOrderNo { font-weight: normal; }
        #treeFormBtn { margin-top: 0; }
       .moreInfoSign {
            background: url('/static/img/icon_question-mark_35x35.png') no-repeat center center;
            margin-right: 10px;
            width: 35px;
            height: 35px;
        }
    }


//------------------------------
//-- Treelist page            --
//------------------------------
    .treeItemDetails {
        margin-left: 0;
        margin-right: 0;
        li {
            margin: 0;
            list-style-type: none;
        }
        label { margin: 0; }
    }

    .treeFilter {
        display: block;
    }
    .rightSideColumn {
        display: none;
    }

    #searchBy {
        padding: 0;
    }

//------------------------------
//-- Tree details page        --
//------------------------------
    #treeDetail {
        .signature { right: 0; }
        .treeDetails { margin-top: 30px; }
        .treeByVisitor { display: block; margin: 0; width: 60%; height: auto; float: right; }
    }

//------------------------------
//-- Map page                 --
//------------------------------
    #headerContentMap {
        width: 100%;
        box-shadow: none;
        padding: 5px 35px 5px 15px;
    }

    #bodyContentMap {
        width: 100%;
    }

    #showMoreLessControl {
        display: block;
    }

    .collapsed {
        height: 10vh;
        padding: 5px 15px 15px 15px;
        overflow: hidden;
    }

    .collapsed:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom, rgba(86, 86, 85, 0), rgba(86, 86, 85, 1) 90%);
        width: 100%;
        height: 3em;
    }


    #mapCanvas {
        width: 100%;
        min-height: 300px;
        margin-bottom: 0;
        border: 0;
    }

    #bntFullScreen { float: none; }

    #informationLinks {
        width: 100%;
        height: 49px;
        border: 0;
        #pac-input {
            position: absolute;
            width: auto;
            height: 28px;
            margin: 9px auto;
        }
        #btnFullScreen { width: auto; height: auto; }
        #btnEnterFullScreen {  margin: 10px 15px; }
        .mapBtn { height: 48px; }
        .instructions { margin: 10px 15px; }
    }

    #btnExitFullScreen {
        position: fixed;
        // top: 65px;
        right: 5px;
    }

    // hide the footer on mobile devices
    // .push { display: none; }
    // #pageFooter { display: none; }

    // .treeDetail .gallery { padding: 0 10px; }
    .treeDetail #arrow-left { width: 10%; }
    .treeDetail #arrow-rigth { width: 10%; }
    .treeDetail #gallery-container { width: 80% }

//------------------------------
//-- News page                --
//------------------------------
    .newsInShort { padding: 0; }

    .blogContent .rightSideColumn {
        display: block;
    }


//------------------------------
//-- Stories page             --
//------------------------------
    .story { padding: 5px; }


//------------------------------
//-- Contact page             --
//------------------------------
    div#leftCol,
    div#middleCol,
    div#rightCol {
        width: 100%;
        padding:0;
        font-size: 1em;
    }
    div#rightCol {
        margin: 25px 0 0 0;
        border: 1px dotted #C1B29C;
        .plugin_googlemap, .google-map-container { height: 400px; }
    }
    .contactPerson, .staffProfile {
        flex: 0 0 100%;
        background: #DDCCB2;
        box-shadow: none;
    }

//------------------------------
//-- User signup page         --
//------------------------------
.mandatoryFields,
.additionalUserInfo {
    width: 100%;
    display: block;
}

.form-block {
    width: 100%;

    .field-wrapper {
        margin: 10px 0;

        p {
            padding-right: 5%;
        }
    }

    .simple-label {
        width: 100%;
        text-align: left;
    }

    input {
        width: 100%;
    }
}

// ------------------------------
//-- Worksheet                 --
// ------------------------------

.field-wrapper {
    input:not([type='checkbox']):not([type='radio']) {
      width: 100%;
    }
}

details {
    background: #DDCCB2;
}